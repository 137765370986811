.sort_wrap {
  position: relative;
  margin-left: auto;
  @media (max-width: 768px) {
    margin-left: 0;
  }

  &__btn {
    display: flex;
    align-items: center;
    gap: 9px;
    font-size: 14px;
    line-height: 24px;
    color: #4a5568;

    &:hover {
      opacity: .7;
    }

    &.popover_btn {
      padding: 12px 12px 14px;
      width: 100%;
      border-bottom: 1px solid #e1ecf1;
    }
  }

  &__popover {
    position: absolute;
    top: -4px;
    right: 0;
    z-index: 1;
    min-width: 219px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 16px rgba(48, 49, 51, 0.12);
    @media (max-width: 768px) {
      right: unset;
      left: 0;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 14px;
  }

  &__item {
    input {
      position: absolute;
      z-index: -1;
      opacity: 0;

      &:checked + label:before {
        background-image: url('./icons/checkmark.svg');
      }
    }

    label {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      line-height: 20px;
      color: #4a5568;
      cursor: pointer;
      transition: opacity .3s;

      &:hover {
        opacity: .7;
      }

      &:before {
        content: '';
        box-sizing: border-box;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}
