.default_button_wrapper {
    .default_button {
        text-align: center;
        display: inline-flex;
        font-family: "Roboto", sans-serif;
        font-weight: 500;
        font-size: 20px;
        height: 52px;
        text-transform: initial;
        min-width: 100%;
        width: 100%;
        border-radius: 8px;
        white-space: nowrap;
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        -ms-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        transition: all 0.3s linear;
        box-shadow: none;
        @media (max-width: 768px) {
            height: 38px;
        }
        &:disabled {
            opacity: 0.5;
            box-shadow: none;
        }
        &.default_button_contained {
            background-color: #1CBB32;
            color: #ffffff;
            border: 1px solid #1CBB32;
            font-size: 20px;
            @media (max-width: 768px) {
                font-size: 14px;
            }

        }

        &.default_button_contained_grey,
        &.contained_grey  {
            background-color: #E3E8EB;
            color: #4A5568;
            font-size: 20px;
            border: 1px solid #E3E8EB;
        }

        &.default_button_outlined {
            color: rgba(2, 0, 24, 0.5);
            border: 1px solid transparent;
            &.cancel_btn {
                background: #eff3f3;
                color: #7f8fa4;
            }
            &.blue_btn {
                border: 1px solid #0253b3;
                color: #0253b3;
            }
            &.delete_btn {
                border: 1px solid #d0021b;
                color: #d0021b;
            }
        }
        div {
            color: #ffffff;
        }
    }
    .full_btn_width {
        width: 100%;
    }
}

button:disabled {
    background: grey;
    opacity: 0.65;
    cursor: not-allowed;
    animation: none;
}

@media only screen and (max-width: 1365px) {
    .default_button_wrapper {
        .default_button {
            font-size: 10px;
        }
    }
}
