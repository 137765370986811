.product_request_page{
  background: #FAFAFA;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;



  .request_content{
    width: 460px;
    max-width: 460px;
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(48, 49, 51, 0.16);
    border-radius: 8px;
    padding: 36px 40px;
    box-sizing: border-box;
    .title{
      padding-bottom: 36px;
      font-weight: 500;
      font-size: 28px;
      line-height: 36px;
      text-align: center;
    }
    form{
      >button{
        width: 100%;
        height: 52px;
        background: #1CBB32;
        box-shadow: 0px 6px 12px rgba(28, 187, 50, 0.08);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        cursor: pointer;
      }
    }
  }
}

.dialog_request{
  padding: 60px 0;
  display: flex;
  justify-content: center;
  flex-flow: column;
  width: 100%;
  align-items: center;
  >div{
    font-weight: 500;
    font-size: 28px;
    text-align: center;
  }
  >img{
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 1279px) {
  .product_request_page {
    padding: 20px 0;
    box-sizing: border-box;
    overflow-y: auto;
    align-items: flex-start;
    @media (max-width: 768px) {
      padding: 20px 16px;
    }
    .request_content{
      padding: 35px;
      height: auto;
      .title{
        padding-bottom: 30px;
        font-size: 25px;
        line-height: 30px;
      }
    }

  }
  .dialog_request{
    padding: 40px 0;
    >div{
      font-size: 25px;
    }
    >img{
      margin-bottom: 25px;
    }
  }
}