.specifications_wrapper {
    background: #fafafa;
    display: flex;
    align-items: flex-start;
    padding: 32px 32px 16px;

    @media (max-width: 768px) {
        padding: 24px 16px;
    }

    .specifications_block {
        width: 100%;
        overflow: auto;
        height: 335px;
        display: flex;

        &::-webkit-scrollbar {
            width: 10px;
            height: 10px;
        }

        &::-webkit-scrollbar-track {
            -webkit-border-radius: 2px;
            background: #fafafa;
        }

        &::-webkit-scrollbar-thumb {
            -webkit-border-radius: 5px;
            border-radius: 5px;
            background: rgba(74, 85, 104, 0.25);
        }

        &::-webkit-scrollbar-thumb:window-inactive {
            background: rgba(74, 85, 104, 0.25);
        }
    }

    .specifications {
        width: 60%;
        min-width: 60%;
        display: flex;
        flex-flow: column;

        .info_wrapper {
            display: flex;
            flex-wrap: wrap;

            >div {
                width: 50%;
            }
        }

        .block {
            display: flex;
            align-items: flex-start;
            padding-bottom: 14px;

            @media (max-width: 768px) {
                display: block !important;
            }

            span {
                width: 170px;
                min-width: 170px;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #1a202c;
                padding-right: 30px;
            }

            p {
                font-size: 16px;
                line-height: 24px;
                color: #4a5568;
                display: flex;
                flex-wrap: wrap;
                word-break: break-word;
                padding-right: 5px;
            }
        }
    }

    .title_block {
        height: 26px;
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;
        text-transform: capitalize;
        color: #1a202c;
        padding-bottom: 18px;
    }

    .info_product {
        padding-right: 10px;
        display: flex;
        flex-flow: column;

        .info {
            font-size: 14px;
            line-height: 20px;
            color: #4a5568;
        }
    }
}

@media only screen and (max-width: 1279px) {
    .specifications_wrapper {
        .specifications_block {
            flex-flow: column;
            height: auto;

            .specifications {
                width: 100%;
                min-width: 100%;
            }

            .info_product {
                padding-top: 20px;
                padding-right: 0;

                .info {
                    table {
                        tbody {
                            tr {
                                td {
                                    padding-right: 20px;
                                    max-width: 400px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}