.auth-box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.auth_content {
    width: auto;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    justify-content: center;
    form {
        width: 460px;
        height: auto;
        background: #FFFFFF;
        box-shadow: 0px 8px 16px rgba(48, 49, 51, 0.16);
        border-radius: 8px;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        box-sizing: border-box;
        padding: 64px 40px;
        position: relative;
        @media (max-width: 768px) {
            width: 100%;
            min-width: 340px;
            padding: 35px 16px !important;
        }
    }

}

.res_pas_ok{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
}

.logo_block_auth{
    display: flex;
    justify-content: center;
    padding-bottom: 36px;
}

.title_auth{
    display: flex;
    justify-content: center;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 33px;
    padding-bottom: 36px;
}

.descriptions_auth{
    margin-top: -24px;
    display: flex;
    justify-content: center;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    color: #4A5568;
    padding-bottom: 36px;
    text-align: center;
}

.back_block{
    margin-bottom: 73px;
    a{
        display: flex;
        align-items: center;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        color: #2D3D45;
        img{
            margin-right: 11px;
        }
    }
}

.password_reset{
    display: flex;
    justify-content: flex-end;
    margin-top: 18px;
    margin-bottom: 40px;
    a{
        cursor: pointer;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #2D3D45;
    }
}

.auth_bg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img {
        background-size: cover;
        left: 0;
        position: absolute;
        background-position: center center;
        top: 0;
        width: 100%;
        height: 100%;
    }
}

.auth_btn_wrapper{
    display: flex;
    .default_button_wrapper{
        width: 100%;

        .default_button{
            width: 100%;
        }
    }
}


@media only screen and (max-width: 1279px) {
    .auth_content{
        form{
            padding: 35px;
        }
    }
    .logo_block_auth{
        padding-bottom: 20px;
        img{
            background-size: 100% 100%;
            width: 200px;
        }
    }
    .title_auth{
        padding-bottom: 25px;
        font-size: 25px;
        line-height: 28px;
    }
    .descriptions_auth{
        margin-top: -15px;
        font-size: 15px;
        line-height: 22px;
        padding-bottom: 30px;
        flex-flow: column;
    }
    .password_reset{
        margin-bottom: 20px;
    }
    .back_block{
        margin-bottom: 40px;
    }
    .res_pas_ok{
        img{
            background-size: 100% 100%;
            width: 100px;
            height: 100px;
        }
    }

}
