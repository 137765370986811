.buy_together{
  background: #FAFAFA;
  display: flex;
  height: 100%;
  align-items: flex-start;
  padding: 0 32px 16px;
  @media (max-width: 768px) {
    padding: 0 16px 16px;
  }
  .block_product{
    padding: 32px 0;
    margin-bottom: 16px;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    &::-webkit-scrollbar-track {
      -webkit-border-radius: 2px;
      background: #fafafa;
    }
    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 5px;
      border-radius: 5px;
      background: rgba(74, 85, 104, 0.25);
    }
    &::-webkit-scrollbar-thumb:window-inactive {
      background: rgba(74, 85, 104, 0.25);
    }
  }

}