.tab_block{
  padding: 0 32px;
  background: #ffffff;
  border-top: 1px solid rgba(207, 216, 220, 0.5);
  border-bottom: 1px solid rgba(207, 216, 220, 0.5);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  @media (max-width: 768px) {
    padding: 0 6px;
  }
  button{
    padding: 16px 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #2D3D45;
    @media (max-width: 768px) {
      padding: 10px !important;
    }

  }
  .active{
    background: #FF010B;
    color: #FFFFFF;

  }


}
.wrapper_page_inner{
  display: flex;
  flex-flow: column;
  height: 100%;
  & > div:nth-child(3){
    height: 100%;
  }
  .title_block_inner{
    padding: 32px 32px 24px;
    @media (max-width: 768px) {
      padding: 24px 16px !important;
    }
    .breadcrumbs_product{
      padding-bottom: 32px;
      display: flex;
      align-items: center;
      overflow-x: auto;
      a{
        color: #4A5568;
        opacity: 0.75;
        font-size: 16px;
        line-height: 19px;
        white-space: nowrap;
        img{
          margin: 0 14px;
        }
      }
      span{
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #4A5568;
        white-space: nowrap;
        @media (max-width: 768px) {
          white-space: unset;
          margin-top: 20px;
        }
      }
    }
    .name{
      font-weight: 500;
      font-size: 28px;
      line-height: 33px;
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        font-size: 20px !important;
        line-height: 25px !important;
      }
      img{
        margin-right: 12px;
        background-size: 100% 100%;
        width: 36px;
        height: 36px;
      }
    }
    .info_product_inner{
      display: flex;
      justify-content: space-between;
      padding-top: 8px;
      .art{
        padding: 8px 16px;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #1A202C;
        background: rgba(203, 213, 224, 0.25);
        border-radius: 8px;
      }
    }
  }
}

@media only screen and (max-width: 1279px) {
  .wrapper_page_inner{
    .title_block_inner{
      padding: 24px 32px;
      .breadcrumbs_product{
        padding-bottom: 20px;
        flex-wrap: wrap;
        a{
          font-size: 14px;
          line-height: 17px;
          img{
            margin: 0px 10px 2px;
          }
        }
      }
      .name{
        font-size: 25px;
        line-height: 27px;
      }
    }
    .tab_block{
      button{
        padding: 13px 21px;
        font-size: 15px;
        line-height: 17px;
      }
    }
  }

}